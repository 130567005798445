import { AccountStore } from "@root/stores/accountStore";
import { EventPageStore } from "@root/stores/eventPageStore";
import { Coordinate } from "@root/utils/entities";
import { USA_BOUNDS } from "@root/utils/types";
import { startTransition, useEffect } from "react";

export const useMapSetup = (
  store: EventPageStore,
  accountStore: AccountStore
) => {
  useEffect(() => {
    const initializePage = async () => {
      store.showLoading();
      try {
        await store.loadCategories();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            startTransition(() => {
              accountStore.setUserLocation(
                position.coords.latitude,
                position.coords.longitude
              );
            });
          },
          (error) => {
            console.error("Error getting geolocation:", error);
            accountStore.clearUserLocation();
          },
          { timeout: 5000 }
        );
      } finally {
        store.hideLoading();
      }
    };

    initializePage();
  }, [store, accountStore]);

  useEffect(() => {
    if (!accountStore.locationRequested) return;

    const setupMap = (coordinate?: Coordinate) => {
      if (coordinate) {
        startTransition(() => {
          store.searchEvents(coordinate);
        });
      } else {
        startTransition(() => {
          store.setMapBounds(USA_BOUNDS.northEast, USA_BOUNDS.southWest);
        });
      }
    };

    setupMap(accountStore.userLocation);
  }, [accountStore.locationRequested, accountStore, store]);
};
