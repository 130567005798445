import moment from "moment";
import { LoginResult } from "./dtos";
import { Coordinate } from "./entities";

export type LoginCredentials = {
  email: string;
  password: string;
};

export type LoginFormState = {
  loginResult: LoginResult;
  email?: string;
};

export type Pagination = {
  page: number;
  pageSize: number;
  pageCount: number;
  offset: number;
  totalCount: number;
};

export type DateRange = {
  start?: moment.Moment | Date;
  end?: moment.Moment | Date;
};

export type MapBounds = {
  northEast: Coordinate;
  southWest: Coordinate;
}

export type NumberRange = {
  min?: number;
  max?: number;
};

export type State = {
  name: string;
  abbreviation: string;
}

export type FilterType = "location" | "age" | "dateRange" | "category";

export type InputType =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export type ErrorType = "unauthorized" | "serverError" | "validation";

export type GeolocationPayload = {
  latitude: number;
  longitude: number;
}

export const USA_BOUNDS: {
  northEast: Coordinate;
  southWest: Coordinate;
} = {
  northEast: { latitude: "49.38", longitude: "-66.94" },
  southWest: { latitude: "25.82", longitude: "-124.39" },
};